import { NavigateBefore } from '@mui/icons-material';
import { Avatar, Button, ButtonBase, Card, CardContent, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Box } from '@mui/system';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { BookingContext } from '../../Contexts/BookingContext';


const useStyles = (theme) => ({
    selectionHeader: {
        paddingTop: theme.spacing(2),
    },
    selectionHeaderTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    selectionCardItem: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    selectionCardItemButton: {
        width: '100%'
    }
});

class BookingProcess extends Component {
    render() {
        const { t, classes, i18n } = this.props;

        const getStageOptions = (workflowStage) => {
            if (workflowStage && typeof workflowStage['options'] !== 'undefined') {
                return workflowStage['options'] ?? [];
            }
            return [];
        };

        const getStageTitle = (stageItem) => {
            let title = stageItem.title ?? '';
            return workflowTranslator(title, <span>&nbsp;</span>);
        };

        const getStageDescription = (stageItem) => {
            let description = stageItem.description ?? '';
            return workflowTranslator(description, <span>&nbsp;</span>);
        };

        const getOptionTitle = (stageItem, item) => {
            let titleField = 'title';
            let title = '';
            if (typeof stageItem.optionTitle !== 'undefined') {
                titleField = stageItem.optionTitle;
            }

            if (typeof item[titleField] !== 'undefined') {
                title = item[titleField];
            }
            
            return workflowTranslator(title);
        };

        const getOptionDescription = (stageItem, item) => {
            let descField = 'description';
            let description = '';
            if (typeof stageItem.optionDescription !== 'undefined') {
                descField = stageItem.optionDescription;
            }

            if (typeof item[descField] !== 'undefined') {
                description = item[descField];
            }
            return workflowTranslator(description);
        };

        const workflowTranslator = (text, defaultReturn = '') => {
            if (typeof text !== 'string') {
                const lang = (i18n.language).substring(0,2);
                if (typeof text[lang] === 'string') {
                    return text[lang];
                }
                return defaultReturn;
            }
            return text;
        }

        const hasPrice = (stage) => {
            if (typeof stage.gross_amount !== 'undefined') {
                return true;
            }
            return false;
        }

        const getStagePrice = (stage) => {
            if (hasPrice(stage)) {
                return <span>{t('price')}: {stage.gross_amount}</span>;
            }
            return null;
        }

        const getOptionAvatar = (opt) => {
            if (typeof opt.avatar_url !== 'undefined') {
                return <Box style={{ flexShrink: 1, alignSelf: 'center', margin: -5, marginRight: 15  }}>
                    <Avatar src={opt.avatar_url} sx={{ width: 60, height: 60 }}></Avatar>
                </Box>;
            }
            return null;
        }

        const getOptionAvatarCompensation = (opt) => {
            if (typeof opt.avatar_url !== 'undefined') {
                return <Box style={{ flexShrink: 1, alignSelf: 'center' }}>
                    <Box style={{ width: 60 }}></Box>
                </Box>;
            }
            return null;
        }


        const getStageHeader = () => {
            return <BookingContext.Consumer>
                {({workflowStage, handleGoBack, passed }) => (
                    <Box className={classes.selectionHeader}>
                        <Box className={classes.selectionHeaderTitle}>
                            <Box flexShrink={1} width={0}>
                                <Button aria-label="back" variant='contained' disabled={passed.length === 0} onClick={(e) => handleGoBack(e)}>
                                    <NavigateBefore />
                                </Button>
                            </Box>
                            <Box flexGrow={1} textAlign='center'>
                                <Typography component="h1" variant="h6">
                                    {getStageTitle(workflowStage)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box textAlign='center'>
                            <Typography component="p" color="secondary">
                                {getStageDescription(workflowStage)}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </BookingContext.Consumer>;
        };

        const getStageOptionItem = (item) => {
            return <BookingContext.Consumer>
                {({workflowStage, handleStageOptionClick}) => (
                    <Card  className={classes.selectionCardItem}>
                        <ButtonBase className={classes.selectionCardItemButton} onClick={(e) => handleStageOptionClick(e, item)}>
                            <CardContent style={{ display: 'flex', width: '100%' }}>
                                {getOptionAvatar(item)}
                                <Box style={{ flexGrow: 1, alignSelf: 'center' }}>
                                    <Typography component="h1" variant="h6">
                                        {getOptionTitle(workflowStage, item)}
                                    </Typography>
                                    <Typography component="p" color="secondary" sx={{ whiteSpace: 'pre-line' }}>
                                        {getOptionDescription(workflowStage, item)}
                                    </Typography>
                                    {hasPrice(item) && <Box textAlign='center'>
                                        <Typography component="p" color="secondary">
                                            {getStagePrice(item)}
                                        </Typography>
                                    </Box>}                          
                                </Box>
                                {getOptionAvatarCompensation(item)}
                            </CardContent>
                        </ButtonBase>
                    </Card>
                )}
            </BookingContext.Consumer>;
        }

        return <BookingContext.Consumer>
            {({workflowStage, stage}) => (
                <Box>
                    {workflowStage && <React.Fragment>
                        {getStageHeader()}
                        {getStageOptions(workflowStage).map((item, index) => {
                            return <React.Fragment key={'booking-process-selection-item-'+index}>
                                {getStageOptionItem(item)}
                            </React.Fragment>;
                        })}
                    </React.Fragment>}
                </Box>
               
            )}
        </BookingContext.Consumer>;
    }
}

BookingProcess.contextType=BookingContext;

const  TranslatedComponent = withTranslation('translation')(BookingProcess);
export default withStyles(useStyles)(TranslatedComponent);