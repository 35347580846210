import { Add as AddIcon, EventNote as EventNoteIcon } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AppointmentService from '../../Services/AppointmentService.js';
import { YmdFormat } from '../../Utility/BookingFormatter.js';
import CachingHelper from '../../Utility/CachingHelper.js';
import { AuthContext } from '../Contexts/AuthContext.js';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText.js';
import CustomFab from '../Elements/CustomFab.js';
import MainContent from '../Elements/MainContent.js';
import AppointmentList from './Lists/AppointmentList.js';

const useStyles = (theme) => ({

});

class Appointments extends Component {
    constructor(props) {
        super(props);
        
        const defaultListType = 'future';
        this.state = {
            listType: defaultListType,
            list: CachingHelper.get('appointments_' + defaultListType) || [],
            confirmationPending: false,
            bookable: false,
        };
    }
    
    componentDidMount() {
        this.reloadData();
    }

    reloadData = (newListType = null) => {
        const { listType } = this.state;
        let params = {
            type: listType
        };

        if (newListType) {
            params.type = newListType;
        }

        AppointmentService.isBookingEnabled()
            .then(response => {
                this.setState({
                    bookable: true
                });
            }).catch(error => {
                this.setState({
                    bookable: false
                });
            });

        return AppointmentService
            .list(params)
            .then(response => {
                this.setState({
                    listType: params.type,
                    list: response
                }, () => {
                    CachingHelper.set('appointments_' + params.type, response);
                });
            });
    } 

    handleOnCancelClick = (e, item) => {
        this.setState({
            confirmationPending: item,
        });
    }

    handleOnCancelConfirmationClickAgree = (e) => {
        const { confirmationPending } = this.state;
        if (typeof confirmationPending.id !== 'undefined') {
            AppointmentService
                .cancel({id: confirmationPending.id})
                .then(res => {
                    this.reloadData();
                    this.setState({
                        confirmationPending: false
                    });
                });
        }
    }

    handleOnCancelConfirmationClickDisagree = (e) => {
        this.setState({
            confirmationPending: false
        });
    }

    handleChangeListType = (e, listType) => {
        if (CachingHelper.get('appointments_' + listType)) {
            this.setState({
                list: CachingHelper.get('appointments_' + listType)
            });
        }
        this.reloadData(listType);
    }

    handleDownloadIcs = (e) => {
        const { t } = this.props;
        let filename = t('appointments') + '_' + YmdFormat(new Date());
        AppointmentService.ics({}, filename);
    }

    render() {
        const { t, classes, listTypes } = this.props;
        const { list, listType, confirmationPending, bookable } = this.state;

        const isBookingEnabled = (configurations) => {
            if ((configurations.portal_online_booking ?? true) && bookable) {
                return true;
            }
            return false;
        }
        
        const isCancellationEnabled = (configurations) => {
            if (configurations.portal_date_cancellation_offset ?? false) {
                return true;
            }
            return false;
        }

        const isToggleAllowed = (configurations) => {
            if (configurations.portal_appointments_future_only ?? false) {
                return false;
            }
            return true;
        }

        return (
            <AuthContext.Consumer>
                {({ clientConfigurations }) => (
                    <MainContent
                        title={<ConfiguredCustomText fallback={t('appointments')} section={'appointments'} label={'title'}/>}
                        textPrimary={<ConfiguredCustomText fallback={t('text-head-appointments')} section={'appointments'} label={'primary'}/>}
                        textSecondary={isCancellationEnabled(clientConfigurations) && <ConfiguredCustomText fallback={t('text-subhead-appointments')} section={'appointments'} label={'secondary'}/>}
                        actionComponent={
                            <React.Fragment>
                                {isBookingEnabled(clientConfigurations) && <Box flexShrink={1} alignSelf="center">
                                    <CustomFab
                                        component={Link}
                                        to="/appointments/booking"
                                    >
                                        <AddIcon className={classes.extendedIcon} />
                                        {t('book-appointment')}
                                    </CustomFab>
                                </Box>}
                            </React.Fragment>
                        }
                    >
                        <Grid container>
                            <Grid item xs={12} md={4} marginBottom={2}>
                                {listType === 'future' && list.length > 0 && <Button onClick={this.handleDownloadIcs}><EventNoteIcon />&nbsp;{t('download-all')}</Button>}
                            </Grid>
                            {isToggleAllowed(clientConfigurations) && <Grid item xs={12} md={8} textAlign='right' marginBottom={2}>
                                <ButtonGroup>
                                    {listTypes.map((i, index) => {
                                        return <Button key={'date-type-btn-'+i} variant="contained" onClick={(e) => this.handleChangeListType(e, i)} color={listType === i ? 'primary' : 'secondary'}>
                                            {t(i)}
                                        </Button>
                                    })}
                                </ButtonGroup>
                            </Grid>}
                        </Grid>
                        <AppointmentList items={list} onCancelClick={this.handleOnCancelClick}/>
                        <Dialog
                            open={confirmationPending}
                            onClose={this.handleOnCancelConfirmationClickDisagree}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {t('cancel-appointment')}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {t('cancel-appointment-confirmation')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleOnCancelConfirmationClickDisagree} color='secondary'>{t('cancel')}</Button>
                                <Button onClick={(e) => this.handleOnCancelConfirmationClickAgree(e)} color='primary' autoFocus>{t('confirm')}</Button>
                            </DialogActions>
                        </Dialog>
                    </MainContent>
                )}
            </AuthContext.Consumer>
        );
    }
}

Appointments.defaultProps = {
    listTypes: [
        'future', 
        'past'
    ]
};

const  TranslatedComponent = withTranslation('translation')(Appointments);
export default withStyles(useStyles)(TranslatedComponent);